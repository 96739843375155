/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_T9t0ZjajV",
  aws_user_pools_web_client_id: "mqs4lbehp8q84a1jerj3eaqe4",
  oauth: {
    domain: "auth.oyukimaru.com",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "https://oyukimaru.com",
    redirectSignOut: "https://oyukimaru.com",
    // redirectSignIn: "http://localhost:3000",
    // redirectSignOut: "http://localhost:3000",
    responseType: "token",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;
